






















import { Component, Vue } from 'vue-property-decorator';

@Component({})
export default class Unauthorized extends Vue {
    logout(): void {
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        this.$auth.logout({
            returnTo: window.location.origin,
        });
        sessionStorage.setItem('token', '');
    }
}
